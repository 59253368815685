import { LaptopCanvas, BallCanvas, ComputersCanvas, StarsCanvas } from './canvas';
import Hero from './Hero';
import Navbar from './Navbar';
import About from './About';
import Tech from './Tech';
/* import Experience from './Experience'; */
import Works from './Works';
import Contact from './Contact';

export {
  Hero,
  Navbar,
  About,
  Tech,
  /*Experience,*/
  Works,
  Contact,
  LaptopCanvas, 
  BallCanvas, 
  ComputersCanvas, 
  StarsCanvas
}