import React from 'react';
import { Tilt } from 'react-tilt';
import { motion } from 'framer-motion';
import { styles } from '../styles';
import { services } from '../constants';
import { fadeIn, textVariant } from '../utils/motion';
import { SectionWrapper } from '../hoc';

const ServiceCard = ({index, title, icon}) => {
  return (
    <Tilt className="xs:w-[250px] w-full">
      <motion.div 
      variants={fadeIn('right', 'spring', 0.5 * index, 0.75)}
      className='w-full green-pink-gradient p-[1px] rounded-[20px] shadow-card'>
        <div
          options={{
            max: 45,
            scale: 1,
            speed: 450
          }}
          className="bg-tertiary rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col"
        >
          <img src={icon} alt={title} className='w-16 h-16 object-contain' />
          <h3 className='text-white text-[20px] font-bold text-center'>{title}</h3>
        </div>
      
      </motion.div>
    </Tilt>
  )
}


const About = () => {
  return (
    <>
      <motion.div variants={textVariant()} >
        <p className = {styles.sectionSubText} >Introduction</p>
        {/* <h2 className = {styles.sectionHeadText} >Overview</h2> */}
      </motion.div>

      <motion.p variants={fadeIn("", "", 0.1, 1)} className='mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]'>
      Hello! I’m Parth, a college student with a strong enthusiasm for technology and programming. My studies have given me a solid grasp of several programming languages, including Swift, Python, JavaScript, and C. I’m passionate about exploring both front-end and back-end development, as well as the innovative fields of machine learning and AI.

On the front-end, I’m dedicated to creating engaging and intuitive user interfaces that enhance user experience. I enjoy the challenge of designing and implementing features that are both functional and visually appealing. On the back-end, I focus on developing robust systems that support and optimize these user experiences, ensuring everything works seamlessly behind the scenes.

My interest in machine learning and AI drives me to investigate how data can be leveraged to develop intelligent systems and solve complex problems. I’m particularly intrigued by how these technologies can be applied to real-world scenarios to drive meaningful advancements.

As I continue to advance in my studies and professional development, I’m excited about the opportunities to work on impactful projects and collaborate with others who share a similar passion for technology. Feel free to browse my portfolio and reach out if you’d like to connect or discuss potential projects.
      </motion.p>

      <div className="mt-20 flex flex-wrap gap-10">
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {...service}/>
        ))}
      </div>
    </>
  )
}

export default SectionWrapper(About, "about");